<template>
  <v-dialog
    persistent
    :fullscreen="fullscreen && isMobile"
    :max-width="maxWidth"
    v-bind="$attrs"
    content-class="roundout"
    :style="cssStyle"
    @input="$emit('input', $event)">
    <v-toolbar :class="cssClass" flat dense>
      <slot name="title"></slot>
      <v-spacer></v-spacer>
      <v-btn v-if="closable"
             icon
             @click.stop="close">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card flat tile>
      <v-card-text>
        <slot/>
      </v-card-text>
      <v-card-actions v-if="actions">
        <v-spacer/>
        <c-btn
          text color="secondary"
          @click="close">
          {{ $t('actions.close') }}
        </c-btn>
        <c-btn
          v-if="isMedia"
          text color="primary"
          @click="saveFunction()">
          {{ $t('actions.save') }}
        </c-btn>
        <slot name="actions"/>
        <a id='hiddenLink' :href=canSaveFunction style='display:none;' download>Download Pic</a>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'CDialog',
  props: {
    actions: {
      type: Boolean,
      default: true
    },
    isMedia: {
      type: Boolean,
      default: false
    },
    canSave: {
      type: Boolean,
      default: false
    },
    fullscreen: {
      type: Boolean,
      default: true
    },
    canSaveFunction: {
      type: String,
      default: null
    },
    cssClass: {
      type: String,
      default: null
    },
    cssStyle: {
      type: String,
      default: null
    },
    closable: {
      type: Boolean,
      default: true
    },
    maxWidth: {
      type: [String, Number],
      default: 650
    },
    roundout: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState('application', ['isMobile']),
    contentClass () {
      return this.roundout ? 'round-out' : ''
    }
  },

  methods: {
    close () {
      this.$emit('input', false)
      this.$emit('close')
    },
    saveFunction () {
      document.getElementById('hiddenLink').click()
    }
  }
}
</script>

<style>
.roundout {
  border-radius: 16px !important;
}

.fixed-top-publication {
  position: sticky !important;
  top: 0 !important;
  z-index: 100 !important;
}
</style>
