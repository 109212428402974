<template>
  <section>
    <div
      style="border-radius: 10px; border: 1px solid #d5d5d5; overflow: hidden;"
    >
      <p class="title_info" :class="classModals">
        <span
          ><strong>{{ $t("models.withdraw.attention") }}</strong></span
        >
        {{ $t("models.withdraw.msg_attention") }}
      </p>
      <div :class="boxClass">
        <div class="content-datospago" :class="classModals">
          <p style="font-size: 11px; line-height: 1em;">
            <strong>{{ $t("models.withdraw.title_info_holder") }}</strong>
          </p>

          <button @click="goToSetting" class="button-edit" :class="classModals">
            ✎ {{ $t("actions.edit").toUpperCase() }}
          </button>
          <div class="separa xs-size"></div>
          <p
            style="color: white; background-color: #5f5f5f; font-size: 11px;    display: inline-block;    line-height: 1em;    padding: 3px 10px;"
          >
            ∘ {{ $t("models.withdraw.data_colletion") }}
          </p>
          <p class="text-wallet">
            {{ $t("models.user.name") }}:
            <span
              ><strong>{{ withdrawData.fullName }}</strong></span
            >
          </p>
          <p class="text-wallet">
            {{ $t("models.user.address") }}:
            <span
              ><strong>{{ withdrawData.address }}</strong></span
            >
          </p>
          <p class="text-wallet">
            {{ $t("models.user.cp") }}:
            <span
              ><strong>{{ withdrawData.postalCode }}</strong></span
            >
          </p>
          <p class="text-wallet">
            {{ $t("models.user.city") }}:
            <span
              ><strong>{{ withdrawData.city }}</strong></span
            >
          </p>
          <p class="text-wallet">
            {{ $t("models.user.cif") }}:
            <span
              ><strong>{{ withdrawData.documentNumber }}</strong></span
            >
          </p>
        </div>
        <div class="content-datospago" :class="classModals">
          <p style="font-size: 11px; line-height: 1em;">
            <strong>{{ $t("models.withdraw.title_info_payment") }}</strong>
          </p>
          <button @click="goToSetting" class="button-edit" :class="classModals">
            ✎ {{ $t("actions.edit").toUpperCase() }}
          </button>
          <div class="separa xs-size"></div>
          <p class="title_method" :class="classModals">
            <strong>{{ typeWithdraw }}</strong>
          </p>
          <div v-if="isBank">
            <p class="text-wallet">
              {{ $t("models.withdraw_bank_new_info.iban_number") }}:
              <span
                ><strong>{{ withdrawMethod.info.bankAccount }}</strong></span
              >
            </p>
            <p class="text-wallet">
              {{ $t("models.withdraw_bank_new_info.bank_name") }}:
              <span
                ><strong>{{ withdrawMethod.info.bankName }}</strong></span
              >
            </p>
            <!-- <p class="text-wallet">
             {{ $t("models.withdraw_bank_new_info.bank_country") }}:
              <span
                ><strong>{{ withdrawMethod.info.bankCountry }}</strong></span
              >
            </p> -->
            <p class="text-wallet">
              {{ $t("models.withdraw_bank_new_info.bank_currency") }}:
              <span
                ><strong>{{ withdrawMethod.info.bankCurrency }}</strong></span
              >
            </p>
            <p class="text-wallet">
              {{ $t("models.withdraw_bank_new_info.swift_number") }}:
              <span
                ><strong>{{ withdrawMethod.info.swiftAccount }}</strong></span
              >
            </p>
          </div>
          <div v-else>
            <p class="text-wallet">
              {{ withdrawMethodTypeText.account }}
              <strong>{{ withdrawData.fullName }}</strong>
              <span
                ><br />{{ withdrawMethodTypeText.email
                }}<strong> {{ withdrawMethod.accountId }}</strong></span
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "PaymentDetail",
  props: {
    wallet: Object
  },
  computed: {
    ...mapState("withdraws", ["withdrawData", "withdrawMethods"]),
    ...mapState("application", ["isMobile"]),
    withdrawMethod() {
      return this.withdrawMethods[0] || {};
    },
    isBank() {
      return this.withdrawMethod.type === "bank";
    },
    withdrawMethodTypeText() {
      const type = this.withdrawMethod.type;
      return {
        account: this.$t(`models.withdraw_new_method.${type}.account`),
        email: this.$t(`models.withdraw_new_method.${type}.email`)
      };
    },
    typeWithdraw() {
      const type = this.withdrawMethod.type;
      return this.$t(`withdraws.withdraw_methods.types.${type}`);
    },
    boxClass() {
      return {
        boxrow: !this.isMobile,
        boxcolumn: this.isMobile
      };
    },
    classModals() {
      return {
        dark: this.$vuetify.theme.dark
      };
    }
  },
  methods: {
    goToSetting() {
      this.$router.push({ name: "settings", query: { tab: "service" } });
    },
    gotoAcademy() {
      this.$router.push({ name: "settings", query: { tab: "service" } });
    }
  }
};
</script>

<style lang="scss" scoped>
.boxrow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px;
  .content-datospago {
    padding: 10px;
    margin: 1px;
    background-color: #f4f4f4;
    width: 50%;
    &.dark {
      background-color: #535353 !important;
    }
  }
}
.boxcolumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;
  .content-datospago {
    padding: 10px;
    margin: 1px;
    background-color: #f4f4f4;
    &.dark {
      background-color: #535353 !important;
    }
  }
}
.title_method {
  font-size: 12px;
  background-color: #e3e3e3;
  display: inline-block;
  line-height: 1em;
  padding: 3px 10px;
  &.dark {
    background-color: #5f5f5f !important;
  }
}
.title_info {
  text-align: center;
  font-size: 12px;
  line-height: 1em;
  background-color: #faebd7;
  padding: 6px;
  &.dark {
    background-color: #a78b66 !important;
  }
}
.text-wallet {
  font-size: 12px;
  line-height: 1.4em;
}
.button-edit {
  font-size: 12px;
  text-decoration: underline;
  color: #008000;
  &.dark {
    color: greenyellow !important;
  }
}
</style>
