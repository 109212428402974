import Api from '@/api/Api'

export default (api, apiMethod = 'list') => ({
  props: {
    title: String,
    titleAction: Object,
    maxItems: [Number, String],
    pageItems: { type: [Number, String], default: 0 },
    noTitle: Boolean,
    noFilter: Boolean,
    noPagination: Boolean,
    sortable: { type: Boolean, default: false }
  },

  data () {
    return {
      loading: false,
      items: [],
      data: {},
      metadata: {},
      page: 1,
      perPage: 10,
      loadItemsTiemout: 1000,
      totalItems: 0,
      sort: '',
      sortDesc: false,
      loadOnMounted: true
    }
  },
  computed: {
    totalPages () {
      return 1 + (this.totalItems / this.perPage)
    }
  },

  watch: {
    page (value) {
      this.stopLoading()
      this.loadItems()
    },
    perPage (vaue) {
      this.page = 1
      this.loadItems()
    },
    sort () {
      this.stopLoading()
      this.page = 1
      this.loadItems()
    },
    sortDesc () {
      this.stopLoading()
      this.page = 1
      this.loadItems()
    },
    query (value) {
      this.page = 1
      this.$emit('update:filter', value)
      this.startLoading()
    }
  },

  methods: {
    stopLoading () {
      this.onLoadStop()
      clearTimeout(this.timeout)
    },

    startLoading (timeout) {
      this.stopLoading()
      if (this.onLoadStart() === false) {
        return
      }
      this.timeout = setTimeout(() => {
        this.loadItems()
      }, timeout || this.loadItemsTiemout)
    },

    tableQuery () {
      const query = this.query || {}
      const params = {
        ...query,
        page: this.page - 1,
        perPage: this.perPage
      }
      if (this.sortable) {
        const dir = this.sortDesc ? 'desc' : 'asc'
        params.order = `${this.sort}:${dir}`
      }

      return params
    },

    async loadItems () {
      if (this.onLoadItems() === false) {
        return
      }
      if (this.cancelToken) {
        this.cancelToken.cancel()
      }
      this.loading = true
      const cancelToken = Api.cancelToken()
      this.cancelToken = cancelToken
      const { data, error } = await api[apiMethod](this.tableQuery(), {
        cancelToken: cancelToken.token
      })
      if (!error) {
        this.data = data
        this.items = data.data.map(this.mapItem)
        this.metadata = data.metadata
        if (!this.maxItems) {
          this.totalItems = data.metadata.totalCount
        } else {
          this.totalItems = this.items.length
        }
        this.$emit('loaded', data)
      } else {
        this.items = []
        this.$emit('loaded', {})
      }
      this.cancelToken = null
      this.loading = false
      this.onLoadFinished(data, error)
    },

    mapItem (item) {
      return item
    },

    onLoadStart () {

    },

    onLoadItems () {

    },

    onLoadFinished () {

    },

    onLoadStop () {

    }
  },

  mounted () {
    if (this.maxItems) {
      this.perPage = parseInt(this.maxItems)
    } else {
      this.perPage = parseInt(this.pageItems) || this.perPage
    }
    if (this.loadOnMounted) this.loadItems()
  }
})
