<template>
  <section>
    <v-alert v-if="isBlockedPayment" dense prominent type="warning">
      {{ $t('withdraws.withdraw_methods.alert_blocked_country') }}
    </v-alert>
    <h1 class="mb-2">{{ $t('withdraws.withdraw_methods.title') }}</h1>
    <WithdrawDataSection class="mb-2"/>
    <WithdrawMethodSection/>
  </section>
</template>
<script>
import WithdrawDataSection from '@/components/withdraws/WithdrawDataSection'
import WithdrawMethodSection from '@/components/withdraws/WithdrawMethodSection'
import {WithdrawActions} from "@/store";
import {mapState} from "vuex";

export default {
  name: 'WithdrawConfigSection',
  components: {
    WithdrawDataSection,
    WithdrawMethodSection
  },
  data() {
    return {
      showWithdrawDataForm: true,
      withdrawMethodsCatalog: []
    }
  },
  computed: {
    ...mapState('withdraws', ['catalogMethodsActive', 'withdrawData', 'withdrawMethods']),
    isBlockedPayment() {
      if (!this.withdrawMethods.length) {
        return false;
      }

      const type = this.withdrawMethods[0]?.type;
      const method = this.catalogMethodsActive.find(c => c.type === type);

      if (!method) {
        return false;
      }

      return !method.countries.includes(this.withdrawData.country);
    }
  },
  mounted() {
    this.$store.dispatch(WithdrawActions.FetchCatalog)
  }
}
</script>
