<template>
  <v-row justify="center">
    <v-col cols="12" md="8">
      <section class="text-center pa-4">
        <img
          width="225"
          src="/images/logo_horizontal.svg?dfk23" alt="logo" title="Logo"/>
        <h1 class="my-5">{{ $t("change_password.title") }}</h1>
        <div v-if="success || error" >
          <div v-if="success">{{ $t('change_password.success_message') }}</div>
          <div v-else>{{ $t('change_password.error_message') }}</div>
          <c-btn to="/" css="my-3">
            {{ $t('actions.back') }}
          </c-btn>
        </div>

        <v-form v-if="!error && !success"
                ref="form" v-model="validForm"
                class="login-form"
                @submit.prevent="submit">
          <PasswordInput outlined
                         dense
                         ref="password"
                         hide-details="auto"
                         v-model="password"
                         :rules="[$vRequired]"/>
          <br>
          <PasswordInput
            outlined
            dense
            :label="$t('models.user.password_confirmation')"
            v-model="passwordConfirmation"
            :rules="[$vRequired,$vConfirmation('passField')]"/>
          <v-row no-gutters class="my-3"
                 justify="space-between" align="center">
            <c-btn
              outlined
              :color="cancelColor"
              to="/">
              {{ $t('actions.cancel') }}
            </c-btn>
            <c-btn @click.stop.prevent="submit">
              {{ $t('actions.save') }}
            </c-btn>
          </v-row>
        </v-form>
      </section>
    </v-col>
  </v-row>
</template>

<script>
import validatable from '@/components/mixins/validatable'
import PasswordApi from '@/api/PasswordApi'

export default {
  name: 'ChangePassword',
  mixins: [validatable('form')],
  props: {token: String},
  metaInfo() {
    return {
      title: this.$t('meta.passwords.title')
    }
  },
  computed: {
    cancelColor() {
      return this.$vuetify.theme.dark ? 'white' : 'secondary'
    }
  },
  data() {
    return {
      error: false,
      success: false,
      password: null,
      passwordConfirmation: null
    }
  },

  methods: {

    async onSubmit() {
      const {error} = await PasswordApi.reset(this.token, this.password)

      this.error = error
      this.success = !error
    }
  }
}
</script>
<style scoped>
.response-message {
  max-width: 450px;
}
</style>
