<template>
  <v-form ref="form" v-model="validForm"
          :dark="false"
          @submit.prevent="submit">
    <v-alert type="error"
             v-if="signupError"
             icon="warning"
             dense outlined>
      {{ signupError }}
    </v-alert>
    <v-row justify="center" dense>
      <v-col cols="12">
        <UsernameInput outlined autofocus
                       class="white-background"
                       dense
                       v-model="username"
                       :rules="[$vRequired]"/>
      </v-col>
      <v-col cols="12">
        <v-text-field outlined
                      color="secondary"
                      dense
                      class="white-background"
                      :label="$t('models.user.email')"
                      v-model="email"
                      :rules="[$vRequired,$vEmail]"></v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center" dense>
      <v-col cols="12">
        <PasswordInput outlined
                       dense
                       class="white-background"
                       ref="password"
                       v-model="password"
                       :rules="[$vRequired]"/>
      </v-col>
      <v-col cols="12">
        <PasswordInput outlined
                       dense
                       class="white-background"
                       :label="$t('models.user.password_confirmation')"
                       v-model="passwordConfirmation"
                       :override-rules="[$vRequired, $vConfirmation('password')]"/>
      </v-col>
    </v-row>
    <div class="term ">
      <v-checkbox outlined
                  dense class="mt-0 small-checkbox"
                  v-model="acceptedTerms"
                  :rules="[$vBoolRequired]">
        <template slot="label">
          <v-col class="star label-term">
            {{ $t('models.user.accept') }}&nbsp;
            <a @click.stop :href="goToConditions" target="_blank"> {{ $t('application.menu.terms_conditions') }}</a>
          </v-col>
        </template>
      </v-checkbox>
    </div>
    <c-btn block
           class=""
           color="success"
           type="submit"
           @click.stop.prevent="submit"
           style="font-weight: bold"
           css="mb-3">
      {{ $t('actions.signup') }}
    </c-btn>
  </v-form>
</template>

<script>
import {SessionActions} from '@/store'
import validatable from '@/components/mixins/validatable'
import {mapState} from 'vuex';
import utils from "@/utils";

export default {
  name: 'LoginForm',
  mixins: [validatable('form')],
  props: {
    dark: {type: Boolean, default: false},
    challenge: {type: Boolean, default: false},
    origin: {type: String, default: 'Landing'},
    small: {type: Boolean, default: false}
  },
  data() {
    return {
      username: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      acceptedTerms: false,
      isAdult: true,
      signupError: ''
    }
  },
  computed: {
    ...mapState('session', ['authenticated']),
    goToConditions() {
      return `https://${this.$t('site')}/conditions`
    }
  },
  methods: {
    user() {
      debugger
      return {
        username: this.username.trim(),
        email: this.email.trim(),
        password: this.password.trim(),
        acceptedTerms: this.acceptedTerms,
        isAdult: this.isAdult,
        lang: this.$i18n.locale.trim()
      }
    },
    async onSubmit() {

      const {data, error} = await this.$store.dispatch(SessionActions.Signup, this.user())
      if (!error) {
        if (!this.small) {
          this.$emit('submit', data)
        } else {
          this.$emit('close')
        }
        /*this.$gtm.trackEvent({
        event: 'GA4',
        category: "ux",
        action: "complete_register_clic",
        label: "complete_register",
        value: 5000,
      });*/
      } else {
        if (data.data && data.data.field) {
          const field = this.$t(`models.user.${data.data.field}`)
          const error = this.$t(`remote_errors.${data.errorCode}`, {field, value: data.data.value})
          this.signupError = error
          this.$vuetify.goTo(0)
        }
      }
    },
    redirect() {
      const origin = sessionStorage.getItem('origin')
      if (origin && origin !== '/login') {
        this.$router.replace(origin)
        sessionStorage.removeItem('origin')
      } else {
        if (this.challenge) this.$emit('logged')
        else {
          this.$router.replace('/home')
        }
      }
    }
  },
  beforeDestroy() {
    if (this.authenticated) {
      /* this.$gtag.event('signUp', { event_category: this.origin })*/
    }
    this.$emit('logged')
    this.$emit('close')
  }
}
</script>
<style scoped>
.white-background >>> .v-input__slot {
  border-radius: 8px !important;
  border-width: 1px !important;
  //background-color: white !important;
}

.term {
  display: flex;
  .label-term{
    //font-size: 14px !important;
  }
  .label-term a{
    //font-size: 14px !important;
  }
}

.white-text {
  //color: white !important;
}

</style>
