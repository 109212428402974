<template>
  <Dialog
    :show="value"
    :modeMobile="isMobile"
    @hide="close"
    :title="$t('models.withdraw.title')"
  >
    <template slot="bodyDialog">
      <div>
        <WalletWithdraw :wallet="wallet" @close="close" />
      </div>
    </template>
  </Dialog>
<!--   <Dialog :show="value" :title="$t('models.withdraw.title')" @close="close">
    <WalletWithdraw :wallet="wallet" @close="close" />
  </Dialog> -->
</template>
<script>
import WalletWithdraw from "./WalletWithdraw";
import Dialog from "@/components/chat/component/Dialog";
import {mapState} from 'vuex'

export default {
  name: "WithdrawDialog",
  components: { WalletWithdraw, Dialog },
  props: {
    value: Boolean,
    wallet: Object
  },
  computed:{
        ...mapState('application', ['isMobile']),
  },
  methods: {
    close() {
      this.$emit("input", false);
    }
  }
};
</script>
