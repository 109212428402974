<template>
  <section>
    <h1 class="mb-1">
      {{ $t('withdraws.withdraw_data.title') }}
      <!--            <c-btn v-if="isCompleted"
                    x-small
                    class="mb-1"
                    @click="showDialog">
                    {{ $t('actions.change') }}
                  </c-btn>-->
    </h1>
    <template v-if="!isCompleted">
      <span v-if="!isCompleted">{{ $t('withdraws.withdraw_data.uncomplete') }}</span>
      <c-btn small
             class="ml-1"
             @click="showWithdraw = !showWithdraw">
        {{ $t('actions.add') }}
      </c-btn>
    </template>
    <WithdrawDataInfo v-else/>
    <!--    <c-dialog
          v-model="dialog"
          :actions="false">
          <WithdrawDataForm
            @cancel="hideDialog"
            @submitted="hideDialog"/>
        </c-dialog>-->
    <c-btn
      v-if="isCompleted"
      small
      class="mt-2 mb-2"
      @click="showWithdraw = !showWithdraw">
      {{ $t('actions.change') }}
    </c-btn>
    <WithdrawDataForm v-if="showWithdraw" @submitted="showWithdraw = !showWithdraw"
                      @cancel="showWithdraw = !showWithdraw"/>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { ProfileActions, WithdrawActions } from '@/store'
import dialog from '@/components/mixins/dialog'
import WithdrawDataInfo from '@/components/withdraws/infos/WithdrawDataInfo'
import WithdrawDataForm from '@/components/withdraws/forms/WithdrawDataForm'
/* import WithdrawDataForm from '@/components/withdraws/forms/WithdrawDataForm' */

export default {
  name: 'WithdrawDataSection',
  mixins: [dialog],
  components: {
    WithdrawDataInfo,
    WithdrawDataForm
  },
  props: {
    dark: {
      type: Boolean,
      default: null
    }
  },
  data () {
    return {
      showWithdraw: false
    }
  },
  computed: {
    ...mapState('withdraws', ['withdrawData']),
    isCompleted () {
      return this.withdrawData.fullName && this.withdrawData.country && this.withdrawData.province && this.withdrawData.city && this.withdrawData.postalCode && this.withdrawData.address
    }
  },
  beforeMount () {
    this.$store.dispatch(ProfileActions.Fetch)
    this.$store.dispatch(WithdrawActions.FetchData)
  }
}
</script>
