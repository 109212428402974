module.exports = {
  withdraw_data: {
    title: 'Payout data',
    description: 'This is the personal data for payouts, .',
    uncomplete: 'Payout data is mandatory for requesting payments',
    personal_data: 'Data',
    business: 'Company',
    vat_data: 'VAT Identification',
    check_change_data: 'Same billing data',
    upload_file_text: 'Select or drop some files',
    tab1: 'Account holder',
    tab2: 'Company',
    tab3: 'Third Party Payment',
    alert: 'If you want to modify this data go to',
    bypass_irpf: 'Bypass IRPF'
  },
  withdraw_methods: {
    title: 'Payout methods',
    no_data: 'There aren\'t any payout method added yet.',
    withdraw_data_needed:
      'You need to add personal payout data before setting up a payout method',
    chose_description: 'Chose the payout method',
    file_uploaded: 'Document of authenticity of account holder',
    file_uploaded_desc: 'help information',
    alert_blocked_country: 'The selected withdrawal method is not available in the withdrawal country. Please change your method or withdrawal details.',
    types: {
      bank: 'Wire transfer',
      cosmo: 'CosmoPayments',
      e_pay_services: 'ePayService',
      paxum: 'Paxum',
      skrill: 'Skrill',
      transfer_wise: 'TransferWise',
      btc: 'Bitcoin',
      paypal: 'PayPal',
      western: 'Western Union',
      payplume: 'PayPlume',
    },
    bank: {
      form_description: 'Bank data',
      info: {
        description_1: 'Recommended for EU and USA',
        description_2: 'Payouts directly to your bank account',
        description_3: 'Local currency of your bank',
        description_4: 'Estimated payout arrival: between 1 and 3 days'
      }
    },
    cosmo: {
      form: {
        label: 'COSMO 9 or 10-digit SAN',
      },
      info: {
        description_1: 'Recommended for EU, UK, CO, MX, BR, PE, CL, UY',
        description_2: 'Open your {link} account',
        description_3: 'Currency: $ y €',
        description_4: 'Estimated payout arrival: Immediately'
      }
    },
    e_pay_services: {
      info: {
        description_1: 'Recommended for: check your country {link}',
        description_2: 'Open your {link} account',
        description_3: 'Currency: $ y €',
        description_4: 'Estimated payout arrival: Immediately'
      }
    },
    paxum: {
      form: { label: 'Email of your Paxum\'s account' },
      info: {
        description_1: 'Recommended for EU, USA and LATAM',
        description_2: 'Open your {link} account',
        description_3: 'Currency: $ y €',
        description_4: 'Estimated payout arrival: Immediately'
      }
    },
    payplume: {
      form: {
        label: 'PayPlume\'s paypout address',
        link_text: 'Your account is linked to {payplume_address}',
        link_action: 'Connect with PayPlume',
        unlink_action: 'Disconnect PayPlume account',
        relink_action: 'Change PayPlume account'
      },
      info: {
        description_1: 'Recommended for: world wide',
        description_2: 'Open your {link} account',
        description_3: 'Currency: $ y €'
      },
      errors: {
        canceled: 'Link account has been canceled by user'
      }
    },
    paypal: {
      info: {
        fee: 'Fee of 5% for each payout',
        description_1: 'Recommended for: world wide',
        description_2: 'Open your {link} account',
        description_3: 'Currency: $ y €',
        description_4: 'Estimated payout arrival: Immediately'
      }
    },
    skrill: {
      info: {
        description_1: 'Recommended for: EU, USA',
        description_2: 'Open your {link} account',
        description_3: 'Currency: $ y €',
        description_4: 'Estimated payout arrival: Immediately'
      }
    },
    transfer_wise: {
      info: {
        description_1: 'Recommended for: EU, USA',
        description_2: 'Open your {link} account',
        description_3: 'Currency: $ y €',
        description_4: 'Estimated payout arrival: Immediately'
      }
    },
    btc: {
      form: {
        label: 'Coinbase email or BTC wallet address'
      },
      info: {
        description_1: 'Recommended for: world wide',
        description_2: 'Our recommended partner: {link}',
        description_3: 'Currency: BTC',
        description_4: 'Estimated payout arrival: Immediately'
      }
    },
    western: {
      info: {
        description_1: 'Recommended for: LATAM',
        description_2: 'Currency: Local currency of your country',
        description_3:
          'Fee: 4,90€ (it could be increased depending on the amount received)',
        description_4: 'Estimated payout arrival: Immediately',
        description_5:
          'It is strictly necessary that the receptor must be the same of account holder'
      }
    }
  }
}
