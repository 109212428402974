import store, { ProfileActions, WithdrawActions } from '@/store'


export default async (to, from, next) => {
  if (to.query.payplume && (to.query.address || to.query.error)) {
    const uuid = [to.query.payplume].flat()[0]
    const address = to.query.address
    await store.dispatch(WithdrawActions.LinkPayplume, { uuid, address, error: to.query.error })
    await store.dispatch(ProfileActions.Fetch)
    console.log('Removing query')
    next({ path: to.path, query: {} })
  }
}
